const CurrentTime = function () {
  var today = new Date();
  today = today.setHours(today.getHours() - 7);
  return formatTime(today, 2);
};

const formatTime = function (datetime, type = 1) {
  // type = 1: format for view, 2: format for db
  var today = new Date(datetime);
  var date = `${today.getFullYear()}-${today.getMonth() < 9 ? 0 : ''}${today.getMonth() + 1}-${
    today.getDate() < 10 ? 0 : ''
  }${today.getDate()}`;
  var time = `${today.getHours() < 10 ? 0 : ''}${today.getHours()}:${
    today.getMinutes() < 10 ? 0 : ''
  }${today.getMinutes()}:${today.getSeconds() < 10 ? 0 : ''}${today.getSeconds()}`;
  if (type == 1) return `${time.slice(0, 5)} ${date.split('-').reverse().join('/')}`;
  return `${date} ${time}`;
};

const formatDate = function (date) {
  var dateStr = `${date.getFullYear()}-${date.getMonth() < 9 ? 0 : ''}${date.getMonth() + 1}-${
    date.getDate() < 10 ? 0 : ''
  }${date.getDate()}`;
  var time = `${date.getHours() < 10 ? 0 : ''}${date.getHours()}:${
    date.getMinutes() < 10 ? 0 : ''
  }${date.getMinutes()}:${date.getSeconds() < 10 ? 0 : ''}${date.getSeconds()}`;
  return `${dateStr} ${time}`;
};

/**
 * Format date from database (UTC +0) to view (GMT +7)
 *
 * @var     {type: String, format: "y-m-d'T'h:i:s'.000Z"}
 * @return  {type: String, format: "h:i d-m-y"}
 */
const formatDateForView = function (date) {
  if (!date) return '';
  let dateArr = date.split('.')[0].split('T');
  let dateGMT = new Date(`${dateArr[0]} ${dateArr[1].slice(0, 8)}`);
  dateGMT = dateGMT.setHours(dateGMT.getHours() + 7);
  return formatTime(dateGMT, 0);
};
const formatDateForViewDateOfBirth = function (date) {
  if (!date) return '';
  let dateArr = date.split('-').reverse().join('/');
  return dateArr;
};

/**
 * Format date from view (GMT +7) to database (UTC +0)
 *
 * @var     {type: String, format: "h:i d/m/y"}
 * @return  {type: String, format: "y-m-d h:i"}
 */
const formatDateForDb = function (date) {
  if (!date) return null;
  let dateArr = date.split(' ');
  let dateGMT = new Date(`${dateArr[1].split('/').reverse().join('-')} ${dateArr[0]}`);
  dateGMT = dateGMT.setHours(dateGMT.getHours() - 7);
  return formatTime(dateGMT, 2);
};

const formatDateV2 = function (date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [day, month, year].join('/');
};

export { CurrentTime, formatDateForView, formatDateForDb, formatDate, formatDateForViewDateOfBirth, formatDateV2 };
